//@import "../printcss/print";

//上書き用
body,header{width: 1200px;}
header{position: static;}
main{padding-top: 0;}

.fullimg img,
.mv img{
    width:1200px !important;
}

.mv,
.bx-wrapper{
    width: 1200px;max-width: 1200px;height: 480px !important;
}

.mv{margin-top: 20px;}

.pricebg1_con:after,
.pricebg2_con:after {border-bottom-color: #876C4B;}

.pricebg1_c:after,
.pricebg2_c:after{
    border-bottom-color: #333333;
}

.pricebg1_w:after,
.pricebg2_w:after{
    border-bottom-color: #9ca352;
}
.pricebg1_n:after,
.pricebg2_n:after{
    border-bottom-color: #AC8446;
}
.pricebg1_m:after,
.pricebg2_m:after{
    border-bottom-color: #937653;
}
.label--b {
    min-width: 70px;
    width: auto;
    padding: 0 6px;
    background: #959182;
    position: relative;
    display: table;
    margin-top: 5px;
}